import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import { useLocation, useNavigate } from 'react-router-dom'

//components
import StyledPageContainer from '../../components/StyledPageContainer/StyledPageContainer'
import PartWise from './PartWise'
import MachineWise from './MachineWise'
import ProductionRecord from './ProductionRecord'
import MyMachineDashboard from './Demography'
import DateSelectorContext from '../../context/DateSelectorContext'

import * as DATE_UTILS from '../../utils/date.utils'
import DateSelector from '../../components/DateSelector'

const pageTabs = [
  { name: 'Part wise', value: 'part-wise' },
  { name: 'Machine wise', value: 'machine-wise' },
  { name: 'Production record', value: 'production-record' },
  { name: 'Demography', value: 'demography' }
]

const TabsContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignSelf: 'flex-end'
}))

const Tab = styled('div')(({ theme, isActive }) => ({
  minWidth: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '8px',
  color: isActive ? theme.palette.primary.main : '#ccccd7',
  cursor: 'pointer',
  borderBottom: isActive
    ? `1px solid ${theme.palette.primary.main}`
    : '1px solid transparent',
  '&:hover': {
    color: `${theme.palette.primary.main}`
  }
}))

const Dashboard = () => {
  //default values
  let defaultTab = pageTabs[0].value

  //hooks
  const navigate = useNavigate()
  const location = useLocation()

  //extract category from URL
  const params = new URLSearchParams(location.search)
  const tab = params.get('tab')

  if (tab) {
    defaultTab = tab
  }

  //component state
  const [activeSubTab, setActiveSubTab] = useState(defaultTab)
  const [workingDate, setWorkingDate] = useState(DATE_UTILS.workingDate())
  const [calenderDate, setCalenderDate] = useState(DATE_UTILS.workingDate())

  const dateChangeHandler = (e) => {
    setCalenderDate(e.target.value)
  }
  const submitHandler = (e) => {
    e.preventDefault()
    setWorkingDate(calenderDate)
  }
  const prevNextHandler = (actionType) => {
    setCalenderDate((prevDate) => DATE_UTILS.updateDate(actionType, prevDate))
    setWorkingDate((prevDate) => DATE_UTILS.updateDate(actionType, prevDate))
  }
  useEffect(() => {
    navigate(`?tab=${activeSubTab}`)
  }, [activeSubTab])

  const handleSubTabChange = (tab) => {
    setActiveSubTab(tab)
  }

  return (
    <StyledPageContainer>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: `1px solid #eeeeee`
        }}
      >
        <TabsContainer>
          {React.Children.toArray(
            pageTabs.map((tab, i) => (
              <Tab
                isActive={activeSubTab === tab.value}
                onClick={() => handleSubTabChange(tab.value)}
              >
                {tab.name}
              </Tab>
            ))
          )}
        </TabsContainer>
        <Box sx={{ padding: '12px 24px' }}>
          <DateSelector
            date={calenderDate}
            dateChangeHandler={dateChangeHandler}
            submitHandler={submitHandler}
            prevNextHandler={prevNextHandler}
          />
        </Box>
      </Box>
      <DateSelectorContext.Provider
        value={{
          selectedDate: workingDate
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100% - 64.8px)',
            overflowY: 'auto'
          }}
        >
          {activeSubTab === 'part-wise' && <PartWise />}
          {activeSubTab === 'machine-wise' && <MachineWise />}
          {activeSubTab === 'production-record' && <ProductionRecord />}
          {activeSubTab === 'demography' && <MyMachineDashboard />}
        </Box>
      </DateSelectorContext.Provider>
    </StyledPageContainer>
  )
}

export default Dashboard
